@import url("../fonts/font-awesome/font-awesome.min.css");

@import (optional) 'colorbox.less';

.font-font-awesome {
  font-family: "FontAwesome";
}


html, body {
    color: @text-color;
}

h1, h2, h3, h4 {
    color: @text-colour;
}

header {
    height: auto;
    margin-bottom: 0;
}

.container-full {
    margin: 0 auto;
    width: 100%;
}

/* PRELOADER
------------------------------------------- */
.page-preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #fff;
  text-align: center;
  z-index: 500;
  padding-top: 300px;
}

div.divider.divider-dotted {
	height:10px;
	border:0;
	background:url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKAQMAAAC3/F3+AAAABlBMVEX///+QkJApn3LQAAAAAnRSTlMAgJsrThgAAAAOSURBVHheYwCCUAdcJAAnnALqo5TBzAAAAABJRU5ErkJggg==') repeat-x center;
}
div.divider.divider-dotted:after {
	display:none;
}
div.divider.divider-dotted.mb {
    margin-bottom: 60px;
}

.phone-number-sm {
    background-color: #fff;
    text-align: right;
    padding: 10px;

    @media (max-width: 475px) {
        text-align: center !important;
    }

    a {
        color: #333;
        font-family: @content-font;
        font-weight: @content-bold-weight;
        font-size: 1.2em;

        &:hover {
            text-decoration: none;
        }

        i {
            display: inline-block;
            vertical-align: middle;
            margin-top: -3px;
        }
    }
}

.phone-number {
    position: fixed;
    display: inline-block;
    top: 0;
    right: 30px;
    background-color: rgba(0, 0, 0, 0.75);
    padding: 15px 25px;
    z-index: 1000;
    transition: all 0.2s ease;

    &:hover {
        background-color: rgba(0, 0, 0, 1);
    }

    a {
        color: #E2CCC0;
        font-family: @content-font;
        font-weight: @content-bold-weight;
        font-size: 1.1em;
        transition: all 0.2s ease;

        &:hover {
            text-decoration: none;
            color: #fff;
        }

        i {
            display: inline-block;
            vertical-align: middle;
            margin-top: -3px;
            color: #E2CCC0;
            margin-right: 5px;
            font-size: 1.1em;
        }
    }
}

#main-nav {
    background-color: @navbar-back-colour;

    .navbar {
        height: 150px;
    }

    .navbar-header {
        img.logo {
            margin-top: 30px;
        }
    }

    .navbar-default {
        background-color: @navbar-back-colour;

        .navbar-brand {
            font-family: @navbar-font;
            font-size: @navbar-font-size;
            background-color: @navbar-back-colour;
            color: #fff;
            text-transform: uppercase;
            height: 150px;
            line-height: 150px;

            &.navbar-brand-active {
                background-color: @navbar-back-colour;
            }
        }

        .navbar-nav {
            li {
                transition: all 0.2s ease;
                a {
                    font-family: @navbar-font;
                    font-size: @navbar-font-size;
                    background-color: @navbar-back-colour;
                    color: #fff;
                    text-transform: uppercase;
                    height: 150px;
                    line-height: 150px;
                    transition: all 0.2s ease;

                    @media (max-width: 768px) {
                        height: 50px;
                        line-height: 50px;
                    }

                    &:hover {
                        background-color: @navbar-hover-back-colour;
                    }
                }
            }

            li.active a {
                background-color: @navbar-back-colour;
            }

            ul.dropdown-menu {
                border: 0;

                li {
                    a {
                        height: 75px;
                        line-height: 75px;
                        width: 200px;
                        border-bottom: 1px solid #eee;
                        font-size: @navbar-font-size;
                        transition: all 0.2s ease;
                    }

                    &:last-child {
                        a {
                            border-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}

#section-main {
    p {
        font-weight: 300;
        font-size: 1.3em;
        line-height: 190%;
        margin-bottom: 45px;

        a {
            color: @content-link-color;
        }
    }

    ul {
        margin-top: -20px;
        margin-bottom: 45px;
        margin-left: 15px;

        li {
            font-weight: 300;
            font-size: 1.3em;
            line-height: 190%;
            text-indent: 10px;

            a {
                color: @content-link-color;
            }
        }
    }
}

.unslider-arrow {
    display: block;
	width: 32px;
	height: 32px;
	top: 50%;
	right: 50px;
	left: auto;
	margin-top: -16px;

	overflow: hidden;

	background: rgba(0,0,0,.2) no-repeat 50% 50%;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAQCAQAAABuQZ3IAAAAi0lEQVR4AU3OISBEQQBAwS0AACS9NxqQgCZpkiYBVddFvWhAAUABAPQCAGC4g/0vTnrBqCfDIZl70J+kMUBPpEwT4FNXxBxz4F1HxHyr4EVTxBLb4EFNxEon4CJSlVNw9AcV9sC16h8osgke1P1ArgXwouVvdQq86ww/GQefusNf7kBviBlxpT8k+gL/Wox4r1d4MwAAAABJRU5ErkJggg==');
	background-size: 7px 11px;

	border-radius: 32px;
	text-indent: -999em;

    &.next {
        right: 50px;
    }

    &.prev {
        left: 50px;
        right: auto;
        -ms-transform: rotate(-180deg);
        transform: rotate(-180deg);
    }
}

.intro-slider {
    .slide {
        position: relative;

        .text {
            position: absolute;
            top: 53%;
            margin-left: 300px;
            z-index: 100;

            @media (max-width: 1024px) {
                top: 27%;
                margin-left: 200px;
                width: 60%;
            }

            @media (max-width: 768px) {
                top: 22%;
                margin-left: 160px;
            }

            h3 {
                background-color: rgba(0, 0, 0, 0.3);
                padding: 10px 20px;
                font-family: @heading-font;
                font-weight: @heading-bold-weight;
                color: #fff;
                font-size: 3.6em;
                line-height: 115%;

                @media (max-width: 1024px) {
                    font-size: 3em;
                }

                @media (max-width: 768px) {
                    font-size: 3em;
                }
            }

            h5 {
                font-family: @content-font;
                font-weight: 100;
                line-height: 145%;
                color: #fff;
                font-size: 1.5em;
                margin-top: 30px;
            }
        }

        .dark-overlay {
            background-color: rgba(0, 0, 0, 0.3);
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: 1;
        }

        a.btn {
            background-color: rgba(0, 0, 0, 0.3);
            position: relative;
            display: inline-block;
            text-transform: uppercase;
            padding: 12px 25px;
            padding-top: 15px;
            z-index: 100;
            color: #fff;
            font-size: 1.2em;
            line-height: 100%;
            vertical-align: middle;
            transition: all 0.3s ease;
            margin-top: 30px;

            &.get-quote {
                background: rgba(0, 0, 0, 0.75) !important;
                border: 2px solid rgba(0, 0, 0, 0.75);
                margin-left: 15px;

                i {
                    display: inline-block;
                    vertical-align: middle;
                    margin-top: -5px;
                }

                &:hover {
                    border: 2px solid rgba(0, 0, 0, 0.45);
                    background-color: rgba(0, 0, 0, 0.45) !important;
                }
            }

            &.view-services {
                background-color: #E2CCC0 !important;
                border: 2px solid #E2CCC0;
                color: #000;

                i {
                    display: inline-block;
                    vertical-align: middle;
                    margin-top: -5px;
                }

                &:hover {
                    color: #fff;
                    border: 2px solid rgba(0, 0, 0, 0.45);
                    background-color: rgba(0, 0, 0, 0.45) !important;
                }
            }
        }
    }
}
.intro-div {
    br {
        display: none;

        @media (max-width: 425px) {
            display: block;
        }
    }

    .big-top-btn {
        font-family: @heading-font;
        font-weight: @heading-light-weight;
        width: 100%;
        font-size: 2em;
        text-align: center;
        padding: 1.2em;
        background-color: #5F292C;
        color: #fff;
        transition: all 0.3s ease;

        @media (max-width: 1024px) {
            font-size: 1.5em;
        }

        @media (max-width: 768px) {
            font-size: 1em;
        }

        &:hover {
            background-color: lighten(#9E9E9E, 5%);
            color: #fff;

            strong {
                color: #fdd835 !important;
            }
        }
    }
}

p.whoarewe {
    font-size: 1.5em;
    line-height: 150%;
    text-align: center;
    font-weight: 100;
}

table.home-features-introduction {
    width: 65% !important;
    margin: 5em auto !important;

    @media (max-width: 425px) {
        width: 90% !important;
    }

    td {
        width: 33.333% !important;
        padding: 0 !important;
        padding-right: 30px !important;
        color: lighten(@text-color, 20%);

        @media (max-width: 768px) {
            padding: 0 30px !important;
            padding-bottom: 50px !important;
        }

        img {
            float: none !important;
            display: block;
            width: 100% !important;
            height: auto !important;
            margin: 0 auto;
        }

        h3 {
            font-weight: bold !important;
            color: @heading-colour;
            margin-top: 10px !important;
            margin-bottom: 20px !important;
        }

        a.btn {
            font-family: @heading-font;
            color: @text-color;
            margin-top: 30px;
            padding: 10px 20px;
            text-transform: uppercase;
            border: 3px solid #ddd;
            font-size: 0.9em;
            transition: all 0.3s ease;

            &:hover {
                background-color: #ddd;
                color: #fff;
            }
        }
    }
}

table.store-locations {
    margin: 2em auto !important;

    @media (max-width: 425px) {
        width: 90% !important;
    }

    td {
        width: 33.333% !important;
        padding: 0 !important;
        padding-right: 30px !important;
        color: lighten(@text-color, 20%);

        @media (max-width: 768px) {
            padding: 0 30px !important;
            padding-bottom: 50px !important;
        }

        img {
            float: none !important;
            display: block;
            width: 100% !important;
            height: auto;
            margin: 0 auto;

            @media (max-width: 768px) {
                width: 200px !important;
            }
        }

        h3 {
            font-weight: bold !important;
            color: @heading-colour;
            margin-top: 10px !important;
            margin-bottom: 20px !important;
        }

        a.btn {
            font-family: @heading-font;
            color: @text-color;
            margin-top: 30px;
            padding: 10px 20px;
            text-transform: uppercase;
            border: 3px solid #ddd;
            font-size: 0.9em;
            transition: all 0.3s ease;

            &:hover {
                background-color: #ddd;
                color: #fff;
            }
        }

        ul {
            margin: 0 !important;
            padding: 0;
            list-style: none;

            li {
                font-size: 14px !important;
                text-indent: 0 !important;
                border-bottom: 1px solid #e5e5e5;
                padding: 5px 0;
                text-transform: uppercase;
            }
        }
    }
}

.mid-page-cta {
    text-align: center;
    padding: 5.5em;
    background-image: url(/img/midpage-cta-bg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 100;

    .dark-overlay {
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
    }

    h2 {
        position: relative;
        z-index: 100;
        color: #fff;
        font-size: 2.5em;
        font-weight: 100;
        margin-bottom: 35px;
    }

    a.btn {
        position: relative;
        display: inline-block;
        text-transform: uppercase;
        padding: 15px 25px;
        z-index: 100;
        color: #fff;
        font-size: 1.2em;
        line-height: 100%;
        vertical-align: middle;
        transition: all 0.3s ease;

        @media (max-width: 425px) {
            display: block;
            width: 100% !important;
            margin: 10px auto;
        }

        &.get-quote {
            background: transparent;
            border: 1px solid #fff;
            margin-right: 15px;

            @media (max-width: 425px) {
                margin-right: 0;
            }

            &:hover {
                border: 1px solid #bbb;
                background-color: rgba(0, 0, 0, 0.15) !important;
            }
        }

        &.view-services {
            background-color: #fbc02d;
            border: 1px solid #fbc02d;
            margin-left: 15px;

            @media (max-width: 425px) {
                margin-left: 0;
            }

            &:hover {
                background-color: #ffb300;
            }
        }
    }

    span {
        color: #fff;
        display: inline-block;
        line-height: 100%;
        position: relative;
        z-index: 100;
        vertical-align: middle;
    }
}
.featured-work, .heading-title {
    h2 {
        display: inline-block;
        position: relative;
        background-color: #fff;
        line-height: 100%;
        margin: 0;
        padding: 0 20px;
        color: @heading-colour;
        font-weight: 400;
    }
}

.featured-work, .enquire-form {
    padding-top: 5em;

    .col-md-12 {
        padding: 0 0 !important;
    }

    div.heading-title.heading-dotted {
    	background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKAQMAAAC3/F3+AAAABlBMVEX///+QkJApn3LQAAAAAnRSTlMAgJsrThgAAAAOSURBVHheYwCCUAdcJAAnnALqo5TBzAAAAABJRU5ErkJggg==') repeat-x center;
    }
    h2 {
        margin-top: 0px;
    }

    h2.page-heading {
        display: inline-block;
        position: relative;
        background-color: #fff;
        line-height: 100%;
        margin: 0;
        padding: 0 20px;
        color: @heading-colour;
        font-weight: 400;
    }

    .mix-grid {
        margin-left: 0;
        margin-right: 0;
    }

    .mix {
        padding-left: 0;
        padding-right: 0;
    }

    .info {
        @media (max-width: 768px) {
            margin-top: 30px;
        }
    }
}


.portfolio-filter {
    margin-top: 30px;
    margin-bottom: 60px;

    .nav-pills {
        display: inline-block;

        a {
            color: #333;
            transition: all 0.3s ease;

            &:hover {
                border-radius: 6px;
            }
        }
    }

    .nav-pills>li.active>a, .nav-pills>li.active>a:focus, .nav-pills>li.active>a:hover {
        background-color: #fbc02d;
        border-radius: 6px;
    }
}

#portfolio {
    margin-top: 60px;

    .item-box {
        position: relative;
        padding: 0 !important;
        margin: 0 !important;
        margin-bottom: 40px;
        overflow: hidden;

        figure {
            width: 100%;
            display: block;
            margin-bottom: 0;
            overflow: hidden;
            position: relative;
            text-align: center;
        }

        .item-hover {
            opacity: 0;
            filter: alpha(opacity=0);
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            text-align: center;
            color: #fff;
            -webkit-transition: all 0.3s;
            -moz-transition: all 0.3s;
            -o-transition: all 0.3s;
            transition: all 0.3s;

            &:hover {
                opacity: 1;
                filter: alpha(opacity=1);
            }

            .overlay {
                background: rgba(0,0,0, 0.5);
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                z-index: 1;
                -webkit-transition: all 1s;
                -moz-transition: all 1s;
                -o-transition: all 1s;
                transition: all 1s;
            }

            .inner {
                position: absolute;
                display: block;
                left: 0;
                right: 0;
                top: 50%;
                bottom: 0;
                margin-bottom: 0;
                width: 100%;
                z-index: 100;
                line-height: 23px;
                height: 54px;
                margin: auto;
                margin-top: 0;

                a.ico-rounded {
                    color: #fff;

                    > i {
                        color: #333;
                        background-color: #fff;
                        width: 50px;
                        height: 50px;
                        line-height: 50px !important;
                        margin: -20px 5px 0 5px;
                        -webkit-transition: all 0.3s;
                        -moz-transition: all 0.3s;
                        -o-transition: all 0.3s;
                        transition: all 0.3s;
                        -webkit-border-bottom-right-radius: 20px;
                        -webkit-border-top-left-radius: 20px;
                        -moz-border-radius-bottomright: 20px;
                        -moz-border-radius-topleft: 20px;
                        border-bottom-right-radius: 20px;
                        border-top-left-radius: 20px;
                    }

                    &:hover {
                        text-decoration: none;

                        i {
                            color: #fff;
                            background-color: lighten(#fbc02d, 5%);
                        }
                    }
                }
            }
        }
    }

    .item-box-desc {
        margin-top: 30px;
        padding-left: 0.5em;

        h3 {
            font-weight: @heading-light-weight;
            font-size: 1.5em;
            margin-bottom: 0;
        }

        ul.categories {
            li {
                a {
                    color: lighten(#333, 15%);
                    font-size: 0.84em;
                    text-transform: capitalize;

                    &:hover {
                        color: darken(#fbc02d, 10%);
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

.enquire-form, .precision-club-form {
    background-color: #fff;
    padding: 3em 0;

    .header {
        h2, h5 {
            text-align: center;
        }

        h5 {
            font-family: @content-font;
            text-transform: uppercase;
            font-size: 0.9em;
            color: lighten(#2D2D37, 25%);
            letter-spacing: 2px;
            word-spacing: 1px;
            margin-bottom: 15px;
        }

        h2.big-heading {
            font-size: 2.5em;
            text-transform: uppercase;
            margin-top: 0;
        }

        .border {
            margin-top: 25px;
            text-align: center;

            h6 {
                display: inline-block;
                border-top: 9px solid lighten(#2D2D37, 25%);
                padding: 0 2.75em;
            }
        }
    }

    .form {
        margin-top: 50px;

        input[type=text], input[type=tel], input[type=email] {
            height: 50px;
            line-height: 50px;
        }

        input[type=text], input[type=tel], input[type=email], textarea {
            background-color: #F5F5F5;
            padding: 0 15px;
            border: 0;
            color: lighten(#333, 20%);
        }

        textarea {
            padding: 15px !important;
        }

        button {
            display: block;
            width: auto;
            background-color: lighten(#000, 25%);
            padding: 10px 15px;
            padding-top: 12px;
            color: #fff;
            font-size: 0.9em;
            text-transform: uppercase;
            font-weight: @content-bold-weight;
            border: 0;
            margin-top: 10px;
            transition: all 0.2s ease;

            &:hover {
                background-color: lighten(#000, 35%)
            }

            i {
                vertical-align: middle;
                margin-right: 1px;
            }
        }

        .info-block {
            text-align: center;
            margin-bottom: 35px;

            i {
                color: lighten(#000, 25%);
                display: block;
            }

            label {
                font-weight: 400;
                text-transform: uppercase;
                color: lighten(#333, 40%);
                font-size: 0.9em;
                letter-spacing: 1px;
            }

            p {
                font-weight: bold;
            }
        }
    }
}

.precision-club-form {

    form {
        margin-top: 30px;

        label {
            display: block;
        }

        select {
            width: 100%;
            background-color: #F5F5F5;
            padding: 0 15px;
            height: 50px;
            border: 0;
            color: lighten(#333, 20%);
        }

        select.dob {
            width: 30%;
            margin-right: 5px;
        }
    }
}

#footer {
	color: rgba(255,255,255,0.6);
	background: @footer-back-colour;

    h2, h3 {
        color: #fff;
    }

    h2 {
        margin-top: 35px;
        font-size: 1.75em;
        text-shadow: rgba(0,0,0,0.3) 3px 3px 5px;
        font-weight: bold;
    }

    h3 {
        font-size: 1.2em;
        letter-spacing: 0.1em !important;
        margin-bottom: 25px;
    }

    .container {
        padding-top: 30px;
    }

    .copyright {
        background-color: @footer-back-colour;
    	text-shadow: 1px 1px 1px rgba(0,0,0,0.1);
    	font-size: 13px;
    	display: block;

        .logo-footer img {
            display: block;
            margin-bottom: 30px;
        }

        a {
            color: @footer-link-color !important;
        }

        .copyright-text {
            margin-top: 14px;
        }
    }
}

.hero-image {
    width: 100%;
    height: 300px;
    margin-bottom: 50px;
}

h2.page-heading {
    text-align: center;
    font-weight: @heading-light-weight;
    font-size: 2em;
    text-align: left;
    margin-bottom: 30px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.submenu {
    margin-top: 0;
}

.gallery-container {
    margin-top: 60px;

    .media-item {
        padding: 0;
        margin-bottom: 10px;
    }

    .tag {
        border-color: #e5e5e5;
        cursor: pointer;
        text-transform: capitalize;
        transition: all 0.2s ease;
        font-size: 1em;

        &.active {
            color: darken(#fbc02d, 10%);

            i {
                color: #fbc02d;
            }
        }

        &:hover {
            color: darken(#fbc02d, 10%);
        }

        &.active {
            &:hover {
                border-color: #e5e5e5;
            }
        }

        i {
            vertical-align: middle;
            margin-top: -8px;
            color: lighten(#333, 45%);
        }
    }

    ul.side-nav li.list-group-item>a>.label {
		margin-right:20px;
	}

	ul.side-nav li {
		list-style:none;
	}
	ul.side-nav ul {
		margin:0 0 20px 0;
		padding:0;
	}
	ul.side-nav ul li {
		padding:0 15px;
	}
	ul.side-nav ul li:last-child {
		border-bottom:0;
	}
	ul.side-nav a {
		display:block;
		text-decoration:none;
		color:#333;
		font-size:13px;
		letter-spacing: 1px;
	}
	ul.side-nav a i.fa {
		width:10px;
	}
	ul.side-nav ul li a {
		padding:3px;
		font-size:12px;
	}
	ul.side-nav>li {
		padding:0;
	}
	ul.side-nav>li>a {
		padding:7px 10px;
	}
	ul.side-nav>li.list-group-item.active {
		border:0;
		background-color:transparent;
	}
	ul.side-nav>li.active>a {
		background-color:transparent;
	}
	ul.side-nav li.list-toggle.active:after,
	ul.side-nav > li.active>a {
		font-weight:700;
	}
	ul.side-nav li.list-toggle:after {
		content: "\f104";
		font-family: FontAwesome;
		position: absolute;
		font-size: 15px;
		right: 10px;
		top: 7px;
		font-weight:normal;
		color:#999;
	}
	ul.side-nav li.list-toggle.active:after {
		content: "\f107";
	}

	ul.side-nav .list-group-item {
		background-color:rgba(0,0,0,0);
		border-left:0;
		border-right:0;

		-webkit-border-radius: 0;
		   -moz-border-radius: 0;
				border-radius: 0;
	}
}

/* COLOR ICONS
--------------------------------------------------- */
.color-icons [class*="-facebook"] {
  color: #5371AD;
}
.color-icons [class*="-twitter"] {
  color: #2AA9E0;
}
.color-icons [class*="-google-plus"] {
  color: #D34829;
}
.color-icons [class*="-rss"] {
  color: #EF5C29;
}
.color-icons [class*="-pinterest"] {
  color: #CE2127;
}
.color-icons [class*="-youtube"] {
  color: #F03434;
}
.color-icons [class*="-instagram"] {
  color: #569DC9;
}
.color-icons [class*="-flickr"] {
  color: #FF0084;
}

.color-icons-borders .icon {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.color-icons-noborders-hovered .icon {
  border-color: transparent;
}

.color-icons-bg [class*="-facebook"] {
  background-color: #5371AD;
  color: #fff;
}
.color-icons-bg [class*="-twitter"] {
  background-color: #2AA9E0;
  color: #fff;
}
.color-icons-bg [class*="-google-plus"] {
  background-color: #D34829;
  color: #fff;
}
.color-icons-bg [class*="-rss"] {
  background-color: #EF5C29;
  color: #fff;
}
.color-icons-bg [class*="-pinterest"] {
  background-color: #CE2127;
  color: #fff;
}
.color-icons-bg [class*="-youtube"] {
  background-color: #F03434;
  color: #fff;
}
.color-icons-bg [class*="-instagram"] {
  background-color: #569DC9;
  color: #fff;
}
.color-icons-bg [class*="-flickr"] {
  background-color: #FF0084;
  color: #fff;
}

.color-icons-hovered [class*="-facebook"]:hover {
  color: #5371AD!important;
  background: #fff;
}
.color-icons-hovered [class*="-twitter"]:hover {
  color: #2AA9E0!important;
  background: #fff;
}
.color-icons-hovered [class*="-google-plus"]:hover {
  color: #D34829!important;
  background: #fff;
}
.color-icons-hovered [class*="-rss"]:hover {
  color: #EF5C29!important;
  background: #fff;
}
.color-icons-hovered [class*="-pinterest"]:hover {
  color: #CE2127!important;
  background: #fff;
}
.color-icons-hovered [class*="-youtube"]:hover {
  color: #F03434!important;
  background: #fff;
}
.color-icons-hovered [class*="-instagram"]:hover {
  color: #569DC9!important;
  background: #fff;
}
.color-icons-hovered [class*="-flickr"]:hover {
  color: #FF0084!important;
  background: #fff;
}

.color-icons-bg-hovered [class*="-facebook"]:hover {
  background-color: #5371AD!important;
  color: #fff !important;
}
.color-icons-bg-hovered [class*="-twitter"]:hover {
  background-color: #2AA9E0!important;
  color: #fff !important;
}
.color-icons-bg-hovered [class*="-google-plus"]:hover {
  background-color: #D34829!important;
  color: #fff !important;
}
.color-icons-bg-hovered [class*="-rss"]:hover {
  background-color: #EF5C29!important;
  color: #fff !important;
}
.color-icons-bg-hovered [class*="-pinterest"]:hover {
  background-color: #CE2127!important;
  color: #fff !important;
}
.color-icons-bg-hovered [class*="-youtube"]:hover {
  background-color: #F03434!important;
  color: #fff !important;
}
.color-icons-bg-hovered [class*="-instagram"]:hover {
  background-color: #569DC9!important;
  color: #fff !important;
}
.color-icons-bg-hovered [class*="-flickr"]:hover {
  background-color: #FF0084!important;
  color: #fff !important;
}

/* UTILITY CLASSES
------------------------------------------- */
/* Lists */
.no-list, .vlist, .hlist, .hlinks, .grid-list, .vlinks, .bxslider {
  list-style: none;
  padding: 0;
  margin: 0;
}
.no-list > li, .vlist > li, .hlist > li, .hlinks > li, .grid-list > li, .vlinks > li, .bxslider > li {
  padding: 0;
  margin: 0;
}

.vlist > li {
  display: block;
}

.hlist, .hlinks {
  font-size: 0;
  zoom: 1;
}
.hlist > li, .hlinks > li {
  display: inline-block;
  font-size: 14px;
}
footer .hlinks.hlinks-icons > li {
  margin: 3px;
}

.hlinks-icons, .hlinks-images {
  margin: -3px;
}
.hlinks-icons > li, .hlinks-images > li {
  margin: 0 3px;
}
.hlinks-icons > li > a, .hlinks-images > li > a {
  line-height: 1;
  padding: 0;
}
.hlinks-icons > li > a .icon, .hlinks-images > li > a .icon {
  display: inline-block;
  text-align: center;
  line-height: 30px;
  height: 30px;
  width: 30px;
  font-size: 12px;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  -o-box-sizing: content-box;
  -ms-box-sizing: content-box;
  box-sizing: content-box;
}
.hlinks-icons > li img, .hlinks-images > li img {
  width: 30px;
  height: auto;
}

.hlinks-icons-round .icon {
  border-radius: 50%;
}

.hlinks {
    display: inline-block;
    top: -10px;
    position: relative;
}
@media (max-width: 991px) and (min-width: 768px) {
    table.table-flex-content.collapse-on-sm {
        width: 65%!important;
    }
}
@media (max-width: 1201px) {
    #main-nav .navbar-default .navbar-nav li a {
        height: 40px;
        line-height: 40px;
    }
}
.mid-page-cta {
    background-position: center;
}
#cboxOverlay, #colorbox {
    display: none !important;
}